import './Hero.css';
import Header from './Header/Header';
import hero_img from '../../assets/hero_image.png';
import hero_img_back from '../../assets/hero_image_back.png';
import heart from '../../assets/heart.png';
import calories from '../../assets/calories.png';

import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'

const Hero = () => {

    const transition = {type:'spring', duration:3}
    const mobile = window.innerWidth<=768 ? true : false;
    return (
       <div className="hero">
            <div className="blur blur-hero"></div>
            {/* **********left side*********** */}
            <div className="left-h">
                <Header />

                {/* the best ad part */}
                <div className="the-best-ad">
                    <motion.div
                        initial={{left:mobile? "165px" : "238px"}}
                        whileInView={{left:'8px'}}
                    transition={{...transition, type:'tween'}} >
                    </motion.div>
                    <span>the best fitness club in the town</span>
                </div>
                

                {/* hero heading */}
                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Shape </span>
                        <span>Your</span>
                    </div>
                    
                    <div>
                        <span>Ideal mode</span>
                    </div>

                    <div>
                        <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
                    </div>
                   
                </div>

                {/* figures */}
                <div className='figures'>
                    <div>
                        <span><NumberCounter preFix='+' start={100} end={140} delay='3'/></span>
                        <span>expert coaches</span>
                    </div>
                    <div>
                        <span><NumberCounter preFix='+' start={100} end={300} delay='3' /></span>
                        <span>members joined</span>
                    </div>
                    <div>
                        <span><NumberCounter preFix='+' start={0} end={50} delay='3' /></span>
                        <span>fitness programs</span>
                    </div>
                </div>

                {/* hero buttons */}
                <div className="hero-buttons">
                    <button className="btn">Get Started</button>
                    <button className="btn">Learn More</button>
                </div>
        </div>
        
            {/* **********right side*********** */}
            <div className="right-h">
                <button className='btn'>Join now </button>

                <motion.div className="heart-rate" initial={{right:'-1rem'}} whileInView={{right:"4rem"}} transition={transition}>
                    <img src={heart} alt="heart" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>

                {/* hero imgage */}
                <img src={hero_img} alt="" className='hero-image'/>
                <motion.img initial={{right:'11rem'}} whileInView={{right:'20rem'}} transition={transition} src={hero_img_back} alt="" className='hero-image-back'/>

                {/* calories */}
                <motion.div className="calories" initial={{right:"37rem"}} whileInView={{right:"28rem"}} transition={transition}>
                    <img src={calories} alt="" />
 
                    <div>
                        <span>Calories Burned</span>
                        <span>220 kcal</span>
                    </div>

                </motion.div>
            </div>
            
        </div>
    )
}

export default Hero;