import './Programs.css'
import {programsData} from '../../data/programsData'
import rightArrow from '../../assets/rightArrow.png'
const Programs = () => {
    return (
        <div className='programs' id="programs">
            {/* header */}
            <div className="programs-header header-text">
                <span className='stroke-text'>Explore our</span>
                <span>Programs</span>
                <spann className="stroke-text">to shape you</spann>
            </div>

            {/* program categories */}
            <div className="programs-categories">
                {programsData.map((data) => (
                    <div className="category">
                        {data.image}
                        <span>{data.heading}</span>
                        <span>{data.details}</span>
                        <div className="join-now">
                            <span>Join Now</span><img src={rightArrow} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Programs;