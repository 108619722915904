import './Plans.css'
import {plansData} from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'

const Plans = () => {
    return (
        <div className='plans-container'>
            <div className="blur plans-blur-l"></div>
            <div className="blur plans-blur-r"></div>
            <div className="plans-header header-text">
                <span className='stroke-text'>Ready to start</span>
                <span>your journey</span>
                <span className='stroke-text'>now with us</span>
            </div>

            {/* plans-card */}
            <div className="plans">
                {plansData.map((plan) =>(
                    <div className="plan" key={plan.id}>
                        {plan.icon}
                        <span>{plan.name} </span>
                        <span>₹ {plan.price}</span>

                        <div className="features">
                            {plan.features.map((feature,i)=>(
                                <div className="feature">
                                    <img src={whiteTick} alt="" />
                                    <span key={i }>{feature}</span>
                                </div>
                            ))}
                        </div>

                        <div>
                            <span>see more benifits &rarr;</span>
                        </div>
                        
                        <button className="btn">Join now</button>
                    </div>  
                    
                ))}
            </div>
        </div>
    )
}

export default Plans; 