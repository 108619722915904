import './Join.css'
import {useRef} from 'react'
import emailjs from '@emailjs/browser'
import { useState } from 'react'

const Join = () => {
    const form = useRef()
    const [inputValue,setInputValue] = useState('')

     const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_flely5e', 'template_u1qjgpm', form.current, 'g9C69RFwypeUIKOJw')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      setInputValue('')
  };


    return (
        <div className='join' id='join-us'>
            <div className="left-join">
                <hr />
                <div>
                    <span className='stroke-text'>Ready to</span>
                    <span>Level up</span>
                </div>
                <div>
                    <span>your body</span>
                    <span className='stroke-text'>with us?</span>
                </div>
            </div>
            <div className="right-join">
                <form ref={form} onSubmit={sendEmail} className='email-container'>
                    <input type="email" name="user_email" placeholder='Enter your Email address' value={inputValue} onChange={(e) => {setInputValue(e.target.value)}}/>
                    <button className='btn btn-join'>Join Now</button>
                </form>
            </div>
        </div>
    )
}

export default Join;